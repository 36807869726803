"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {hasClass, addClass, removeClass, closest, on, findAllIn, findIn, find} from '@elements/dom-utils';

export function init () {
    //mobile toggle
    if(matchMedia('(max-width: 767px)').matches) {
        onFind('.js-toggle-inline-nav', function (toggleNav) {
            on('click', function(evt){
                evt.preventDefault();
                let container = closest('.js-inline-nav-container', toggleNav);

                if(!hasClass('is-open', container)) {
                    addClass('is-open', container);
                } else {
                    removeClass('is-open', container);
                }
            }, toggleNav);
        });

    }

    onFind('.js-inline-nav', function ($inlineNav) {
        let lastId,
            lastScrollTop;

        //scroll spy nav items
        let offset = $inlineNav.getBoundingClientRect().height,
            navItems = findAllIn(".inline-nav__item", $inlineNav);

        let spyItems = navItems.map((item) => {
            return find(item.getAttribute('href'))
        });

        let $firstSpyItem = spyItems[0];
        console.log($firstSpyItem)
        if($firstSpyItem.getBoundingClientRect().top <= window.scrollY + offset) {
            addClass('is-active', closest('li', findIn("[href='#"+$firstSpyItem.getAttribute('id')+"']", $inlineNav)));
        }

        on('scroll', function () {
            let scrollTopPosition = window.scrollY + offset + 75;

            let currentItem = spyItems.map((item) => {
                if (item.getBoundingClientRect().top + window.scrollY < scrollTopPosition) {
                    return item;
                }
            });

            currentItem = currentItem.filter((element) => element !== undefined);

            // Get id of the current element
            currentItem = currentItem[currentItem.length-1];
            let currentItemId = currentItem ? currentItem.id : "";

            if (lastId !== currentItemId) {
                lastId = currentItemId;

                if (currentItemId) {
                    addClass('is-active', closest('li', find("[href='#"+currentItemId+"']")));

                    if(matchMedia('(min-width: 768px)').matches) {
                        let offsetLeft = find("[href='#"+currentItemId+"']").getBoundingClientRect().left - $inlineNav.getBoundingClientRect().left + find("[href='#"+currentItemId+"']").getBoundingClientRect().width;
                        findIn('.inline-nav__state', $inlineNav).style.transform = 'translateX('+offsetLeft+'px)';
                    }
                }
            }

            if (currentItem && getNodeindex(currentItem) > 0) {
                for (let i = 0; i < getNodeindex(currentItem); i++) {
                    addClass('is-active', navItems[i].parentNode);
                }
            }

            //remove active class on scroll up
            if(lastScrollTop > scrollTopPosition) {
                if(currentItem) {
                    let lastItemId = currentItem.nextElementSibling.id;
                    removeClass('is-active', closest('li', find("[href='#"+lastItemId+"']")));
                }
            }

            lastScrollTop = scrollTopPosition;
        }, window)
    });
}

const getNodeindex = elm => [...elm.parentNode.children].indexOf(elm)
