"use strict";
import {on, removeClass, addClass, find, findAll, findAllIn, findIn, hasClass, trigger, closest} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import Collapse from 'bootstrap/js/dist/collapse';
import {toggleClass} from "./elements-packages/dom-utils";

let selectedCategories = {};

export function init() {

    if(typeof tmpCat !== 'undefined' && tmpCat){
        selectedCategories = tmpCat;
    }

    onFind('.destination-select',function (element) {
        on('change', function(evt){
            startFilter();
        }, element);
    });

    onFind('.start-filter-bt',function (element) {
        on('click', function(evt){
            startFilter();
        }, element);
    });

    onFind('.date',function (element) {
        removeClass('hidden',find('.clear-date'))
        removeClass('hidden',find('.filter-date'))
    });

    onFind('.filter-collapse__btn',function (element) {
        removeClass('hidden',find('.clear-date'))
        removeClass('hidden',find('.filter-date'))
    });

    onFind('.clear-date',function (element) {
        addClass('hidden', element);
    });

    let initialPlaceholderTexts = [];
    findAll('.btn-select-fake').forEach(select => {
        initialPlaceholderTexts.push(findIn('.btn-select-fake__placeholder', select).getAttribute('data-select-all'));
    })

    findAll('.selectionWrapper').forEach(function (value, index, elements) {
        console.log('elements', elements)
        let element = elements[index];

        let filterCollapseBtns = findAllIn('.filter-collapse__btn',element);

        filterCollapseBtns.forEach((btn) => {
            on('click', function(evt){
                let filterCollapseBtn = evt.target;
                if(hasClass('filter-collapse__btn', filterCollapseBtn)){
                    let scope = element;
                    if(hasClass('active',filterCollapseBtn)){
                        removeClass('active', filterCollapseBtn);
                    } else {
                        addClass('active', filterCollapseBtn);
                    }

                    if(findAllIn('.filter-collapse__btn.active', element).length > 0){
                        removeClass('hidden',findIn('.filter-collapse-clear',scope));
                        removeClass('hidden',findIn('.start-filter-bt',scope));
                    } else {
                        addClass('hidden', findIn('.filter-collapse-clear', scope));
                    }
                }

            }, btn);

        });


        on('click', function(evt){
            let clearSelection = evt.target,
                selectionWrapper = closest('.selectionWrapper', clearSelection),
                filterCollapseClear = findIn('.filter-collapse-clear', selectionWrapper);

            addClass('hidden', filterCollapseClear);
        }, findIn('.clear-selection',element));

    });


    let frontlinecategries = find('.frontlinecategories');

    if(frontlinecategries){
        // togglefilterbutton
        onFind(findIn('.js-togglemainbox',find('.frontlinecategories')),function (element) {
            new Collapse(find('#collapseCategory')).toggle();
        });

        // delete category
        onFind(findIn('.js-deletecategory',frontlinecategries),function (element) {
            let cid = element.getAttribute('data-cid');
            delete selectedCategories[cid];

            let findInFilter = findIn('label[data-cid="'+cid+'"]', find('.filter-collapse.filter'));
            findIn('input',findInFilter).removeAttr('checked');
            removeClass('active',findInFilter);
            element.remove();
            startFilter();
        });
    }

    if (find('#collapseCategory')){
        on('hidden.bs.collapse', function(evt){
            if (find('.js-togglemainbox')){
                removeClass('active',find('.js-togglemainbox'));
            }
            if (frontlinecategries){
                frontlinecategries.style.display = 'block';
            }
        }, find('#collapseCategory'));

    }

    if (find('#collapseCategory')){
        on('shown.bs.collapse', function(evt){
            if (frontlinecategries){
                frontlinecategries.style.display = 'none';
            }
        }, find('#collapseCategory'));
    }

    onFind('.js-datepicker-event', function (datePicker){
        on('change', function (){
            addClass('is-active', datePicker);

            let clearButtons = findAllIn('.js-btn-clear', closest('.filter', datePicker));
            clearButtons.map((button) => {
                removeClass('active', button);
                findIn('input', button).checked = false;
            });

            if (find('.filter-date')) {
                removeClass('hidden', find('.filter-date'));
            }
            if (find('.clear-date')) {
                removeClass('hidden', find('.clear-date'));
            }
        }, datePicker);
    });

    onFind('.js-ajax-form-overlay', function (form) {
        on('reset.ajax-form', () => {
            findAll('.selectionWrapper').forEach(function (value, index, elements) {
                let element = elements[index];
                findAllIn('.filter-collapse__btn', element).forEach((btn) => {
                    if (hasClass('active', btn)) {
                        removeClass('active', btn);
                    }
                });


                setFilterCount(element, true)
            });
        }, form);

        on('success.ajax-form', () => {
            findAll('.selectionWrapper').forEach(function (value, index, elements) {
                setFilterCount(elements[index]);
            });
        }, form);
    });


    function startFilter(){
        window.form = find('.event-filter');
        window.form.submit();
    }

    function setFilterCount(element, reset = false) {
        if (!reset) {
            let targetSelect;
            findAll('.btn-select-fake').forEach((btn, key) => {
                if (btn.href === (window.location.href + '#' + element.children[0].id)) {
                    targetSelect =  {
                        index: key,
                        btn: btn
                    };
                }
            });

            let targetSelectPlaceholder = findIn('.btn-select-fake__placeholder', targetSelect.btn);
            let selectedFilterCount = findAllIn('.filter-collapse__btn.active', element).length;

            if (selectedFilterCount > 0) {
                targetSelectPlaceholder.innerHTML = selectedFilterCount + ' ' + targetSelectPlaceholder.getAttribute('data-length-text');
            }
            else {
                targetSelectPlaceholder.innerHTML = initialPlaceholderTexts[targetSelect.index];

                let filterCollapseClear = findIn('.filter-collapse-clear', element);

                if(!hasClass('hidden', filterCollapseClear)) {
                    addClass('hidden', filterCollapseClear);
                }
            }
        } else {
            let fakeSelects = findAll('.btn-select-fake');
            fakeSelects.forEach((select, index) => {
                let targetSelectPlaceholder = findIn('.btn-select-fake__placeholder', select);
                let filterCollapseClear = findIn('.filter-collapse-clear', element);

                targetSelectPlaceholder.innerHTML = initialPlaceholderTexts[index];
                if(!hasClass('hidden', filterCollapseClear)) {
                    addClass('hidden', filterCollapseClear);
                }
            })
        }
    }
}
