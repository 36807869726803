"use strict";
import fetch from '@elements/fetch';
import {on, findAll, closest, hasClass, addClass, findIn, removeClass} from '@elements/dom-utils';
import {onFind, initInScope} from "@elements/init-modules-in-scope";
import {onEnterViewPort} from "@elements/viewport-utils";
import loadScript from '@elements/load-script';

let oaMap;

export function init() {
    let apiLoaded = false;

    let index = 0;

    onFind('.js-leaflet-map', function (mapContainer) {
        index = index+1;
        addClass('is-loading', mapContainer);

        onEnterViewPort(mapContainer, function () {
            //todo change key !!!!
            //let apiKey = 'IKFFP3AG-EMWGMGUJ-4OSSMTMS'; /* live key */
            let apiKey = 'IKFFP3AG-EMWGMGUJ-4OSSMTMS';
            // let apiKey = 'IKFFP3AG-EMWGMGUJ-4OSSMTMS';

            if (window['oax']) {
                apiLoaded = true;
            } else {
                loadScript('https://api-oa.com/jscr/oa_head.js?proj=api-trentino&key='+ apiKey +'&callback=window.mapApiLoadedCallback&leaflet_gshim=1&lang=' + _config.lang + '&').then(() => {}).catch(console.error);
            }

            // if (apiLoaded) {
            //     createMap(mapContainer, index);
            // } else {
            //     window.mapApiLoadedCallback = function () {
            //         apiLoaded = true;
            //         createMap(mapContainer, index);
            //     };
            // }

            if (apiLoaded) {
                if (!hasClass('is-loaded', mapContainer)){
                    createMap(mapContainer, index);
                }
            } else {
                if(_config.flexviewScriptLoaded){

                    if (!hasClass('is-loaded', mapContainer)){
                        apiLoaded = true;
                        createMap(mapContainer, index);
                        addClass('is-loaded', mapContainer)
                    }
                }else{
                    window.mapApiLoadedCallback = function () {
                        if (!hasClass('is-loaded', mapContainer)){
                            apiLoaded = true;
                            createMap(mapContainer, index);
                            addClass('is-loaded', mapContainer)
                        }
                    };
                }
            }
        }, {
            offset: window.innerHeight
        });
    });
}

function createMap(mapContainer, index) {
    oa.api.maps(function (oamaps, map) {
        //load geojson
        let pendingRequest = fetch('/static/mapdata/Trentino-Line.geojson', {
            method: 'get'
        });

        pendingRequest.then((result) => {
            return result.clone().json()
        }).then((data) => {
            let layer = L.geoJSON(data, {
                style: function (feature) {
                    return {color: '#006390', weight: 4, fillOpacity: 0};
                }
            });

            if (!findIn('.js-leaflet-map__canvas', mapContainer).getAttribute('id')) {
                findIn('.js-leaflet-map__canvas', mapContainer).setAttribute('id', 'map-' + index);
            }
            new leafletMap(mapContainer, oamaps, layer, index);

            removeClass('is-loading', mapContainer);
        }).catch((error) => {
            if (error.name !== 'AbortError') {
                /*Do error stuff*/
                console.error(error);
            }
        });


    });

    let markerIcon;

    function leafletMap (element, oamaps, layer, index) {
        let self = this;
        this.oamaps = oamaps;
        this.element = element;
        this.canvas = findIn('.js-leaflet-map__canvas', element);
        this.map = null;
        this.markers = [];
        this.geo = [];
        this.clustering = true;
        this.markerCluster = null;
        this.trentinoLayer = layer;

        // store in global variable, because in vanilla you cant store Object in data attribut
        window['leafletObject'+index] = this;

        this.element.setAttribute('data-leaflet-map', this);
        this.element.setAttribute('data-leaflet-object-variable', 'leafletObject'+index);

        self.initMap();
    }

    leafletMap.prototype.initMap = function () {
        let self = this;

        self.mapOptions = {
            mapTypeId : 'oa_map',
            oa: {
                maplibre: false
            }
        };

        self.map = self.oamaps.map(self.canvas, self.mapOptions);

        if(matchMedia('(max-width: 767px)').matches) {
            oaMap =  self.map;
        }else{
            oaMap = self.map.__alp_lf__;
        }

        oaMap.addLayer(self.trentinoLayer);

        oaMap.fitBounds(self.trentinoLayer.getBounds(), {padding: [-10, -10]});

        markerIcon = L.Icon.extend({
            options: {
                iconSize:     [50, 67],
                iconAnchor:   [28, 55],
                popupAnchor:  [0, -48]
            }
        });

        this.markerOptions = new markerIcon({iconUrl: '/static/img/content/pois/poi.png'});

        let myPoiData = window[this.element.getAttribute('data-poi')];
        if(myPoiData){
            this.poiData = myPoiData;
        } else {
            this.poiData = self.canvas.getAttribute('data-poi');
        }

        if (this.poiData) {
            this.setMarker(this.poiData);
        }

        if(self.element.getAttribute('data-poi-url')) {
            let $this = this;

            let pendingRequest = fetch(self.element.getAttribute('data-poi-url'), {
                method: 'get'
            });

            pendingRequest.then((result) => {
                return result.clone().json()
            }).then((markers) => {
                $this.poiData = markers;
                $this.setMarker(markers);
            }).catch((error) => {
                if (error.name !== 'AbortError') {
                    /*Do error stuff*/
                    console.error(error);
                }
            });
        }

        //route map
        if (this.element.getAttribute('data-route')){
            let self = this;

            this.clustering = false;

            function loadRoute(routeUrl, isDefault) {
                let pendingRequest = fetch(routeUrl, {
                    method: 'get'
                });

                pendingRequest.then((result) => {
                    return result.clone().json()
                }).then((data) => {
                    self.drawRoute(data);

                    if (isDefault) {
                        self.defaultRoute = data;
                    }
                }).catch((error) => {
                    if (error.name !== 'AbortError') {
                        /*Do error stuff*/
                        console.error(error);
                    }
                });
            }

            let routeUrl = this.element.getAttribute('data-route');
            loadRoute(routeUrl, true);

            //show stage detail
            findAll('.js-show-stage-detail').map((button) => {
                on('click', function (evt) {
                    evt.preventDefault();

                    loadRoute(button.getAttribute('data-poi'));
                    self.detailRouteView = true;
                }, button)
            });
        }
    };

    leafletMap.prototype.drawRoute = function(data) {
        let self = this;

        if (self.route) {
            oaMap.removeLayer(self.route);
            oaMap.removeLayer(self.markers);
            self.route = null;
        }

        let latlngs = [];
        for (let i = 0, point; point = data[i]; i++) {
            latlngs.push([point.lat, point.lng]);
        }

        let polyline = L.polyline(latlngs, {color: '#00405e', weight: 4});
        oaMap.addLayer(polyline);
        oaMap.fitBounds(polyline.getBounds());

        self.route = polyline;

        self.poiData = data;
        self.setMarker(data)
    };

    leafletMap.prototype.setMarker = function (markers) {
        let self = this;
        if (self.clustering && self.markerCluster) {
            oaMap.removeLayer(self.markerCluster);
            self.markerCluster = null;
        }

        self.markers.map((marker) => {
            oaMap.removeLayer(marker);
        })
        // $.each(self.markers, function (index, marker) {
        //     oaMap.removeLayer(marker);
        // });

        self.markers = [];

        if (self.clustering) {
            loadScript('/static/js/libs/leaflet.markercluster.js').then(() => {
                self.markerCluster = L.markerClusterGroup({showCoverageOnHover: false, polygonOptions: {color: '#ffffff'}});
                self.addMarkers(markers);
                oaMap.addLayer(self.markerCluster);
            }).catch(console.error);
        } else {
            self.addMarkers(markers);
        }
    };

    leafletMap.prototype.addMarkers = function (markers) {
        let self = this;

        for (let i= 0, poi; poi = markers[i]; i++) {
            if (poi.lat && poi.lng) {
                if(poi.lat < 1000 && poi.lat > -1000 && poi.lng < 1000 && poi.lng > -1000){
                    let icon = self.markerOptions;

                    if(poi.poiIcon) {
                        icon = {
                            default: new markerIcon({iconUrl: poi.poiIcon}),
                            active: poi.poiIconActive ? new markerIcon({iconUrl: poi.poiIconActive}) : new markerIcon({iconUrl: poi.poiIcon})
                        };
                    }

                    if(poi.poiCount) {
                        icon = {
                            default: L.divIcon({
                                className: 'leaflet-map-marker',
                                html: '<img src="'+ poi.poiIcon +'" class="test"><span class="leaflet-map-marker__label">'+ poi.poiCount + '</span>',
                                iconSize: [50, 67],
                                iconAnchor: [28, 55],
                                popupAnchor: [0, -48]
                            }),
                            active: L.divIcon({
                                className: 'leaflet-map-marker',
                                html: '<img src="'+ poi.poiIcon +'" class="test"><span class="leaflet-map-marker__label">'+ poi.poiCount + '</span>',
                                iconSize: [50, 67],
                                iconAnchor: [28, 55],
                                popupAnchor: [0, -48]
                            })
                        };
                    }

                    let newMarker = L.marker([poi.lat, poi.lng], {icon: icon.default});

                    newMarker.poiStyles = icon;

                    if(poi.dataInfoAjaxUrl) {
                        newMarker.dataInfoAjaxUrl = poi.dataInfoAjaxUrl;
                    }

                    newMarker.dataId = poi.id;

                    if (!self.clustering) {
                        newMarker.addTo(oaMap);
                    }

                    if(poi.infoBox) {
                        newMarker.infoBox = poi.infoBox;
                    }

                    on('click', function () {
                        if (this.isActive) {
                            self.deactivateAllMarkers();
                        } else {
                            self.deactivateAllMarkers();
                            self.activateMarker(this);
                        }
                    }, newMarker);

                    self.markers.push(newMarker);
                }else{
                    console.error('Invalid coordinates: ', poi);
                }
            }
        }

        if (self.clustering && self.markerCluster) {
            self.markers.map((marker) => {
                self.markerCluster.addLayer(marker);
            })
            // $.each(self.markers, function (index, marker) {
            //     self.markerCluster.addLayer(marker);
            // });

            oaMap.addLayer(self.markerCluster);
        }
    };

    leafletMap.prototype.centerMap = function () {
        let self = this;

        let latLngs = [];

        for (let i= 0, marker; marker = self.markers[i]; i++) {
            latLngs.push(L.latLng(marker._latlng.lat, marker._latlng.lng));
        }

        // Zoom/center map to fit all marker
        if (latLngs.length === 0) {
            oaMap.panTo(self.mapOptions.center);
        } else if (latLngs.length === 1) {
            oaMap.fitBounds(latLngs, {maxZoom: self.mapOptions.zoom});
        } else {
            oaMap.fitBounds(latLngs, {padding: [20, 20]});
        }
    };

    leafletMap.prototype.deactivateAllMarkers = function () {
        let self = this;

        for (let i = 0; i < self.markers.length; i++) {
            self.setDefaultMarkerStyle(self.markers[i]);
            self.markers[i].isActive = false;
        }
    };

    leafletMap.prototype.activateMarker = function (marker) {
        let self = this;

        self.setActiveMarkerStyle(marker);

        marker.isActive = true;

        if(marker.infoBox) {
            self.openInfoBox(marker)
        }
    };

    leafletMap.prototype.setDefaultMarkerStyle = function (marker) {
        if (marker.poiStyles) {
            marker.setIcon(marker.poiStyles.default);
        }

    };

    leafletMap.prototype.setActiveMarkerStyle = function (marker) {
        if (marker.poiStyles.active) {
            marker.setIcon(marker.poiStyles.active);
        }
    };

    leafletMap.prototype.openInfoBox = function (marker) {
        let self = this;

        if(!marker._popup) {
            marker.bindPopup(marker.infoBox, {
                minWidth: matchMedia('(max-width: 767px)').matches ? 240 : 425,
                maxWidth: matchMedia('(max-width: 767px)').matches ? 240 : 425,
                className: 'leaflet-map__infobox'
            });

            marker.openPopup();

            initInScope(marker._popup._container);
            // app.initModules($(marker._popup._container));
        }

        //set marker default style on popupclose
        on('popupclose', function () {
            self.setDefaultMarkerStyle(marker);
        }, marker);

    };
}