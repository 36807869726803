"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {find, on, removeClass} from '@elements/dom-utils';

export function init () {
    onFind('.js-submit-on-change', function (element) {
        on('change', function (evt) {
            startFilter();
        }, element);
    });

    onFind('.tart-filter-bt', function (element) {
        on('click', function (evt) {
            startFilter();
        }, element);
    });

    let frontlinecategries = find('.frontlinecategories');


    if(find('#collapseCategory')){
        on('hidden.bs.collapse', function (evt) {
            if (find('.js-togglemainbox')){
                removeClass('active',find('.js-togglemainbox'))
            }
            if (frontlinecategries){
                frontlinecategries.style.display = 'block';
            }
        }, find('#collapseCategory'));
    }


    if (find('#collapseCategory')){
        on('shown.bs.collapse', function(evt){
            if (frontlinecategries){
                frontlinecategries.style.display = 'none';
            }
        }, find('#collapseCategory'));
    }


}

function startFilter(){
    window.form = find('.event-filter');
    window.form.submit();
}