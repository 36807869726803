"use strict";
import {on,closest} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init() {
    onFind('#chooseCategory', function (item) {
        on('change', function(){
            closest('form',item).submit();
        }, item);
    });
}