"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {hasClass, addClass, removeClass, closest, toggleClass, findIn, on} from '@elements/dom-utils';

export function init () {
    onFind('.js-expandable', function (expandableElement) {
        let expandableContent = findIn('.js-expandable__content', expandableElement);
        let expandableHeight = expandableElement.getAttribute('data-expandable-height') || 350;

        console.log('rectangle', expandableContent.getBoundingClientRect().height);

        if( expandableContent.getBoundingClientRect().height > expandableHeight){
            addClass('is-expandable', expandableElement);

            on('click', (e) => {
                e.preventDefault();
                toggleClass('is-collapsed', expandableElement);
                if(hasClass('is-collapsed', expandableElement)){
                    setTimeout(function(){
                        window.scroll({
                            top: expandableElement.offsetTop - 150,
                            behavior: 'smooth'
                        });
                    }, 300);
                }
            }, findIn('.js-expandable__more', expandableElement));
        } else {
            addClass('is-not-expandable', expandableElement);
        }
        // on('click', function(){
        //     let expandContainer = findIn('.expand-container',closest('.extended-filter', item));
        //     if (!hasClass('is-expanded', expandContainer)){
        //         addClass('is-expanded', expandContainer);
        //
        //         findIn('.expandable-search-field', expandContainer).focus();
        //
        //         window.addEventListener("click", function _listener(evt) {
        //             if (!closest('.extended-filter', item).contains(evt.target)) {
        //                 removeClass('is-expanded', expandContainer);
        //
        //                 window.removeEventListener("click", _listener, true);
        //             }
        //         }, true);
        //
        //     }else {
        //         findIn('form',expandContainer).submit();
        //     }
        // }, item);
    });

}