"use strict";
import {on} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

let socialWebcamShareData = {
    method: 'share',
    href: location.href
};

export function init() {
    onFind('.fb-share-webcams', function (element) {
        on('click', function(evt){
            evt.preventDefault();
            FB.ui(socialWebcamShareData, function(response){});
        }, element);
    });
}