"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {findIn, on, trigger, findAllIn} from '@elements/dom-utils';

export function init() {
    onFind('.js-lightbox-gallery', function (lightbox) {
        findAllIn('.js-lightbox-gallery__open', lightbox).map((openButton) => {
            on('click', function () {
                trigger('click', findIn('.js-lightbox-gallery__item:first-child', lightbox));
            }, openButton);
        });
    });
}