"use strict";
import {on, removeClass, findIn, findAllIn} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import {findAll} from "./elements-packages/dom-utils";

export function init() {
    onFind('.js-clear-selection',function (element) {
        let parent = element.parentNode;
        on('click', function () {
            let clearBtns = findAllIn('.js-btn-clear',parent);
            clearBtns.forEach((btn) => {
                removeClass('active',btn);
                let input = findIn('input',btn);
                input.checked = false;
            });

            let datepicker = findAllIn('.js-datepicker input', parent);

            if (datepicker){
                datepicker.map((datepickerInput) => {
                    datepickerInput.value = '';
                });
            }

            let rangeSlider = findAllIn('.js-range-slider__slider', parent.parentNode);

            if (rangeSlider){
                rangeSlider.forEach((slider) => {
                    let sliderWrapper = slider.parentNode;
                    let dots = findAllIn('.noUi-origin', slider);
                    let connect = findIn('.noUi-connect', slider);
                    let minElement = findIn('.js-range-slider__val-min', sliderWrapper);
                    let maxElement = findIn('.js-range-slider__val-max', sliderWrapper);
                    let startValues = sliderWrapper.getAttribute('data-range-slider-start');
                    startValues = startValues.substring(1, startValues.length - 1).split(',');

                    dots[0].style.transform = 'translate(-1000%, 0px)';
                    dots[1].style.transform = 'translate(0%, 0px)';
                    connect.style.transform = 'translate(0%, 0px) scale(1, 1)';

                    minElement.innerHTML = startValues[0];
                    maxElement.innerHTML = startValues[1];
                });
            }
        }, element)
    });
}