"use strict";

import asyncAppend from "@elements/async-append";
import {findAllIn, on, addClass, closest} from "@elements/dom-utils";
import fetch from '@elements/fetch';
import {onFind} from "@elements/init-modules-in-scope";

export function init(){
    onFind('.js-load-more__start', (loadMore) => {
        on('click', (evt) => {
            evt.preventDefault();

            let container = closest('.js-load-more', loadMore);

            addClass('is-loading', loadMore);

            let targetsByResultId = {};
            let resultContainer = findAllIn('.js-load-more__result', container);

            resultContainer.forEach((element) => {
                let resultId = element.dataset.resultId || 'default';
                if (targetsByResultId[resultId]) {
                    targetsByResultId[resultId] = [...targetsByResultId[resultId], element];
                } else {
                    targetsByResultId[resultId] = [element];
                }
            });

            asyncAppend({
                target: targetsByResultId,
                loading: findAllIn('.js-load-more__loading', container),
                notifications: findAllIn('.js-load-more__notifications', container)
            }, fetch(loadMore.getAttribute('data-href')));
        }, loadMore);
    });
}
