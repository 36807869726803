"use strict";
import {on, addClass, removeClass, findAll, findAllIn} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init() {
    onFind('#bulletinSwitch', function (element) {
        on('change', function(evt){
            let _this = evt.target;
            let url;

            let options = findAllIn('option', _this);
            options.forEach((item) => {
                if(item.selected === true){
                    url = item.getAttribute('data-replace');
                }
            });

            if(url && url !== ""){
                window.location = url;
            }else {
                let myVal = _this.value;

                if (myVal) {
                    findAll('.bulletinDisk').forEach((item) => {
                        addClass('hidden', item);
                    });

                    removeClass('hidden',find('#disk_' + myVal));
                } else {
                    findAll('.bulletinDisk').forEach((item) => {
                        removeClass('hidden', item);
                    });
                }
            }

        }, element);
    });

}