"use strict";
import {on} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init() {
    onFind('.js-select-link', function (select) {
        on('change', function () {
            window.location.href = select.value;
        }, select);
    });
}