"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {find, addClass, removeClass, on} from '@elements/dom-utils';

export function init () {
    if(matchMedia('(max-width: 767px)').matches) {
        return;
    }

    let didScroll,
        offset = 150,
        stopOffset,
        isBottom = false,
        lastScrollTop;

    function scrollHandler(item) {
        let scrollTopPosition = window.scrollY;

        if (lastScrollTop > scrollTopPosition) {
            /*scroll up*/
            if(window.scrollY <= offset) {
                removeClass('affix', item);
            }

            if (scrollTopPosition + window.innerHeight + 200 < stopOffset + item.getBoundingClientRect().height) {
                removeClass('affix-bottom', item);
                item.style.top = '';
                isBottom = false;
            }
        } else if (lastScrollTop < scrollTopPosition && !isBottom) {
            /*scroll down*/
            if(window.scrollY >= offset) {
                addClass('affix', item);
            }

            if (scrollTopPosition + window.innerHeight + 200 >= stopOffset + item.getBoundingClientRect().height) {
                addClass('affix-bottom', item);
                item.style.top = stopOffset - item.getBoundingClientRect().height - 200 + 'px';

                isBottom = true;
            }
        }

        lastScrollTop = scrollTopPosition;
    }

    onFind('.js-affix-share', function (item) {
        stopOffset = find('.pre-footer-slide').getBoundingClientRect().top + window.scrollY;

        on('scroll', function () {
            didScroll = true;
        }, window)

        requestAnimationFrame(function animationFrameHandler() {
            if (didScroll) {
                scrollHandler(item);

                didScroll = false;
            }
            requestAnimationFrame(animationFrameHandler);
        });
    });
}