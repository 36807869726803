"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {hasClass, removeClass, addClass, on} from "@elements/dom-utils";

let defaultOptions = {
    onlyMobile: true
}

export function init (options = defaultOptions) {
    if(options.onlyMobile) {
        if (matchMedia('(min-width: 768px)').matches) {
            return;
        }
    }

    onFind('.js-overlay-toggle', function ($container) {
        on('click', function () {
            let targetId = $container.getAttribute('data-target');
            let $overlayItem = document.getElementById(targetId);

            if (hasClass('is-open', $overlayItem)) {
                removeClass('is-open', $overlayItem);
            } else {
                addClass('is-open', $overlayItem);
            }

        }, $container);
    });
}