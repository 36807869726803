"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {on, closest, trigger, addClass, removeClass, findAll} from '@elements/dom-utils';

export function init () {

    onFind('.js-tab-input', function (element) {
        on('change', function (evt) {
            findAll('.btn-tab-input-toggle').forEach((item) => {
                console.log('item',item);
                removeClass('active',item);
            });

            addClass('active',closest('.btn-tab-input-toggle', element));

            if(matchMedia('(max-width: 767px)').matches) {
                trigger('submit',closest('form',evt.target));
            }
        }, element);
    });


    onFind('.js-trigger-tab-input', function (element) {
        on('click', function (evt) {

            console.log('Asdfsdfdsf',evt.target);
            let triggeredTabInputValue = evt.target.getAttribute('data-tab-input-value');

            findAll('.js-tab-input').forEach((tabInput) => {

                console.log('tabInput.value',tabInput.value);
                console.log('triggeredTabInputValue',triggeredTabInputValue);

                if(tabInput.value === triggeredTabInputValue){
                    tabInput.checked = true;
                    trigger('change',tabInput);
                }

            });

        }, element);
    });
}