"use strict";
import {createSwiper} from "./swiper";
import {onFind} from "@elements/init-modules-in-scope";
import {findIn} from '@elements/dom-utils';

export function init () {
    onFind('.js-img-grid-slider', function (sliderElement) {
        createSwiper(sliderElement, {
                loop: true,
                slidesPerView: 2,
                navigation: {
                    findNextElement: swiperContainer => findIn('.js-img-grid-slider__next', swiperContainer),
                    findPrevElement: swiperContainer => findIn('.js-img-grid-slider__prev', swiperContainer),
                },
                breakpoints: {
                    // when window width is >= 768px
                    768: {
                        slidesPerView: 4,
                    }
                },
            },
            {
                sliderContainer: '.js-img-grid-slider',
                slider: '.js-img-grid-slider__slider'
            });
    });
}

