"use strict";
import {createSwiper} from "./swiper";
import {onFind} from "@elements/init-modules-in-scope";
import {findIn} from '@elements/dom-utils';

export function init () {
    onFind('.js-content-slider', function (sliderElement) {
        createSwiper(sliderElement, {
                loop: true,
                speed: 700,
                slidesPerView: 1,
                threshold: 20,
                navigation: {
                    findNextElement: swiperContainer => findIn('.js-content-slider__next', swiperContainer),
                    findPrevElement: swiperContainer => findIn('.js-content-slider__prev', swiperContainer),
                },
                pagination: {
                    el: swiperContainer => findIn('.js-content-slider__pagination', swiperContainer),
                    clickable: true
                }
            },
            {
                sliderContainer: '.js-content-slider',
                slider: '.js-content-slider__slider'
            });
    });
}

