"use strict";
import {on, findAll, closest, findAllIn, addClass, removeClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    onFind('.js-filterview-btn', function (toggleButton) {
        on('show.bs.tab', function (e) {
            let ariaControl= toggleButton.getAttribute('aria-controls');

            //special behavior when filterview-btn is in slide-tabs tabbing
            if(closest('.slide-tabs', e.target)){
                let group = closest('.js-slide-tabs__pane', e.target);

                findAllIn('.js-filterview-btn', group).map((button) => {
                    removeClass('active', button);
                })

                findAllIn('.js-filterview-btn[aria-controls=' + ariaControl + ']', group).map((activeButton) => {
                    addClass('active', activeButton);
                });
            }else{
                findAll('.js-filterview-btn').map((button) => {
                    removeClass('active', button);
                })

                findAll('.js-filterview-btn[aria-controls=' + ariaControl + ']').map((activeButton) => {
                    addClass('active', activeButton);
                });
            }

        }, toggleButton);
    });
}