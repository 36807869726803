"use strict";
import {on, findIn, findAllIn, closest, hasClass, addClass, removeClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    console.log('init select placeholder')
    onFind('.js-select-placeholder', function (button) {
        let container = closest('.filter', button),
            form = closest('form', button);

        on('click', function () {
            let activeButtons = findAllIn('.filter-collapse__btn.active', container),
                activeDatePicker = findAllIn('.js-datepicker.is-active', container),
                placeholder = findIn(button.getAttribute('data-placeholder'), form),
                placehholderAllText = placeholder.getAttribute('data-select-all');


            if(closest('.collapse', button).getAttribute('data-type') === 'date'){
                if(activeDatePicker.length !== 0 || activeButtons.length !== 0){
                    let datePickerFrom = findIn('[data-date-picker=start]', container).value,
                        datePickerTo = findIn('[data-date-picker=end]', container).value;

                    placeholder.innerHTML = activeButtons || datePickerFrom + ' - ' + datePickerTo;
                    // placeholder.text(activeButtons.text() || datePickerFrom + ' - ' + datePickerTo);
                }else {
                    placeholder.innerHTML = placehholderAllText;
                    // placeholder.text(placehholderAllText);
                }
            }else {
                if(activeButtons.length !== 0){
                    placeholder.innerHTML = activeButtons.length + ' ' + placeholder.getAttribute('data-select-text');
                }else {
                    placeholder.innerHTML = placehholderAllText;
                }
            }
        }, button);
    });
}