"use strict";
import {on, closest, find, hasClass, addClass, removeClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init() {
    onFind('.js-toggle-fullscreen-map', function (item) {
        on('click', function(evt, test){
            evt.preventDefault();
            let _this = hasClass('.js-toggle-fullscreen-map', evt.target) ? evt.target : closest('.js-toggle-fullscreen-map', evt.target);
            let map = find(_this.getAttribute('data-target'));
            let leafletObject = window[map.getAttribute('data-leaflet-object-variable')];


            if(!hasClass('is-fullscreen',map)) {
                addClass('is-fullscreen', map);
            } else {
                removeClass('is-fullscreen', map);

                if (_config.leafletMap) {
                    if (leafletObject.detailRouteView) {
                        leafletObject.drawRoute(find(map.getAttribute('data-leaflet-map').defaultRoute));
                    }
                }
            }

            if(_this.getAttribute('data-scroll-to')) {
                let target = find(_this.getAttribute('data-scroll-to')),
                    offset = 60;

                if(matchMedia('(min-width: 768px)').matches) {
                    offset = 135
                }

                window.scrollTo({
                    top: target.getBoundingClientRect().top - offset,
                    behavior: "smooth"
                });
            }

            let t = setTimeout(function () {

                //init map again
                if (_config.leafletMap) {
                    leafletObject.map.__alp_lf__.invalidateSize();
                    leafletObject.centerMap();
                } else {
                    leafletObject.centerMap();
                }
                clearTimeout(t);
            }, 10);

        }, item);
    });

}