"use strict";
import {on, closest, trigger} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init() {
    onFind('.js-submit-form-on-change', function (item) {
        on('change', function (evt) {
            closest('form',evt.target).submit();
        }, item);
    });
}