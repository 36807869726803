import {addClass, find, removeClass, toggleClass} from "@elements/dom-utils";

export function init() {
    let interval = setInterval(function () {
        console.log('looking for ', window['oa'], 'freedom');
        if (window['oa']) {
            initFlexView();
            clearInterval(interval);
        }
    }, 1000);
}

let ids;

window.backendbasket = window['backendbasket'] || {};

function initFlexView() {
    if (window['showfrontendmap']) {
        var predefined = getTextOfElementById('myBasket');
        console.log('predefined', predefined);
        // set the basket
        if (predefined.length > 2) {
            selectedObjects = JSON.parse(predefined);

            ids = Object.keys(selectedObjects);
            if (ids.length > 0) {
                oa.api.maps(function (oamaps, gm) {
                    flexconf.cfg.what.selected_init = false;
                    flexconf.initDataPointList = ids;
                    fvp = oa.api.flexviewpage(flexconf);
                });
            }
        } else {
            // flexconf.cfg.what.selected_init = false;
            fvp = oa.api.flexviewpage(flexconf);
        }

    } else {
        let map;

        function initialize() {
            let zoom = 6;
            let mapZoomInput = find('#myzoom');
            let mapZoomInputValue = mapZoomInput.value;
            console.log({mapZoomInput, mapZoomInputValue});
            if (mapZoomInputValue != '' && typeof mapZoomInputValue !== 'undefined') {
                zoom = +mapZoomInputValue;
            }

            var myLatlng = new google.maps.LatLng(backendlatitude, backendlongitude);
            var mapOptions = {
                zoom: parseInt(zoom),
                center: myLatlng
            };

            map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

            var marker = new google.maps.Marker({
                position: myLatlng,
                map: map,
                animation: google.maps.Animation.DROP,
                draggable: true
            });

            google.maps.event.addListener(marker, 'dragend', function (evt) {
                this.setAnimation(google.maps.Animation.DROP);

                var setmylatitude = document.getElementById("pimcore_editable_latitude");
                setmylatitude.innerHTML = this.position.lat();

                var setmymylongitude = document.getElementById("pimcore_editable_longitude");
                setmymylongitude.innerHTML = this.position.lng();
            });

            google.maps.event.addListener(map, 'zoom_changed', function () {
                var zoomLevel = map.getZoom();
                console.log(zoomLevel);

                mapZoomInput.value = zoomLevel;
            });
        }

        google.maps.event.addDomListener(window, 'load', initialize);

        // Basket Map
        setTimeout(loadBasketMap, 1000);
    }

    var radioButtons = document.getElementsByName("flexViewContent");
    console.log('radioButtons', radioButtons);

    // attach click handler to all radio buttons
    for (var i = 0; i < radioButtons.length; i++) {
        radioButtons[i].onclick = onRadioButtonClick;
    }
}


function loadBasketMap() {
    var predefined = getTextOfElementById('pimcore_editable_basket')
    if (predefined.length > 2) {
        selectedObjects = JSON.parse(predefined);
    }

    oa.api.maps(
        function (oamaps, gm) {
            // FlexView API configuration
            var conf = {
                "frontendtype": "tour",
                "initMode": "list",
                "initFilterOpen": false,
                "withUrlHash": false,
                "center": [parseFloat(backendlongitude), parseFloat(backendlatitude)],
                "zoom": 5,
                // do not open detail view when an OOI is selected
                "actionOpenType": "none",
                // an OOI is clicked: map marker, infowindow, list entry, ...
                "onOoiClick": function (d, s) {
                    // ignore clicks on map marker (infowindow is opened)
                    if (s != "mapMarker") {
                        // all OOIs of the project are shown right now ?
                        if (document.getElementById("projectContent").checked) {
                            // add OOI id to list of selected objects
                            selectedObjects[d.id] = true;
                            document.getElementById('pimcore_editable_basket').innerText = JSON.stringify(selectedObjects);
                            getNotification('<b>' + d.title + '</b> wurde zur Merkliste hinzugefügt.', 'green');
                        }
                        // only the selected OOIs are shown right now
                        else {
                            // remove OOI id from list of selected objects
                            delete selectedObjects[d.id];
                            //getNotification('Remove: ' + d.title);

                            // update the FlexView API instance with the list of selected OOI ids
                            ids = Object.keys(selectedObjects);
                            console.log('selectedObjects', selectedObjects);

                            let basket = document.getElementById('pimcore_editable_basket');
                            if (ids.length == 0) {
                                console.log('if');
                                window.backendbasket.setDataPointList(new Array());
                                basket.innerText = JSON.stringify({});
                            } else {
                                console.log('else');
                                window.backendbasket.setDataPointList(ids);
                                basket.innerText = JSON.stringify(selectedObjects);
                            }
                            
                            console.log('basket.text', basket.text);

                            getNotification('<b>' + d.title + '</b> wurde von der Merkliste gelöscht.', 'red');
                        }
                    }
                }
            };

            window.backendbasket = oa.api.flexviewpage(conf);
        }
    );
}

function getNotification(text, state) {
    let notifyElement = document.getElementById('notify');
    notifyElement.innerHTML = text;
    toggleClass('on', notifyElement);
    addClass(state, notifyElement);
    let timeout = setTimeout(function () {
        toggleClass('on', notifyElement);
        removeClass(state, notifyElement);
        clearTimeout(timeout);
    }, 2000);
}

function onRadioButtonClick(c) {
    console.log('backendbasket', backendbasket);
    // switch to "all content" ?
    console.log('c.target.value', c.target.value);
    if (c.target.value == "project") {
        // call setDataPointList() without arguments to show all content of a project
        window.backendbasket.setDataPointList();
    }
    // switch to "selected content"
    else {
        // show selected OOIs
        ids = Object.keys(selectedObjects);
        window.backendbasket.setDataPointList(ids);
    }
}

const getTextOfElementById = id => {
    return find(`#${id}`).innerText;
}