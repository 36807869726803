import './scripts/common';
import {find, on} from '@elements/dom-utils';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

import * as affixShare from './scripts/affix-share';
affixShare.init();

import {createAffix} from '@elements/affix';
if (find('.js-inline-nav')) {
    createAffix(find('.js-inline-nav'), {mediaQuery: '(min-width: 768px)'}, {
        base: '.js-inline-nav',
        placeholder: '.js-inline-nav-placeholder',
        container: '.js-inline-nav-container'
    });
}

if (find('.js-affix-map') && matchMedia('(min-width: 768px)').matches) {
    createAffix(find('.js-affix-map'), {mediaQuery: '(min-width: 768px)'}, {
        base: '.js-affix-map',
        placeholder: '.js-affix-map__placeholder',
        container: '.js-affix-map__container'
    });
}

import * as inlineNav from './scripts/inline-nav';
inlineNav.init();

import * as selectLink from './scripts/select-link';
selectLink.init();

import * as leafletMap from './scripts/leaflet';
leafletMap.init();

import * as filterViewToggle from './scripts/filterview-toggle';
filterViewToggle.init();

//events
import * as expandSearch from './scripts/expand-search';
expandSearch.init();

import * as eventFilter from './scripts/event-filter';
eventFilter.init();

import * as clearSelection from './scripts/clear-selection';
clearSelection.init();

import * as brochures from './scripts/brochures';
brochures.init();

import * as bulletinSwitch from './scripts/bulletin-switch';
bulletinSwitch.init();

import * as webcamShare from './scripts/webcam-share';
webcamShare.init();

import * as moodboardFilter from './scripts/moodboard-filter';
moodboardFilter.init();

import * as fullScreenMap from './scripts/fullscreen-map';
fullScreenMap.init();

import * as googleMapStatic from './scripts/google-map-static';
googleMapStatic.init();

import * as selectPlaceholder from './scripts/select-placeholder';
selectPlaceholder.init();

// import {createLightbox} from '@elements/lightbox';
// createLightbox(find('.js-lightbox-gallery'), {plugins: ['video']},{
//     base: '.js-lightbox-gallery',
//     item: '.js-lightbox-gallery__item',
// });


import * as lightboxExtend from './scripts/lightbox-extend';
lightboxExtend.init();

import * as loadMore from './scripts/load-more';
loadMore.init();

import * as flexviewadmin from './scripts/flexviewadmin';
if (_config.flexviewadmin) {
    flexviewadmin.init();
}

import * as imgGridSlider from './scripts/img-grid-slider';
imgGridSlider.init();

import * as headerImageSliderMobile from './scripts/header-image-slider-mobile';
headerImageSliderMobile.init();

import * as webcamSlider from './scripts/webcam-slider';
webcamSlider.init();

import * as contentSlider from './scripts/content-slider';
contentSlider.init();

import * as expandable from './scripts/expandable';
expandable.init();

import * as submitOnChange from './scripts/submit-on-change';
submitOnChange.init();

import * as availabilityDatepicker from '@elements/datepicker';

if (find('.js-availability-datepicker')){
    availabilityDatepicker.createDatepicker(find('.js-availability-datepicker'),
        {
            showMonths: 1
        },
        {
            base: '.js-availability-datepicker',
            input: '.js-availability-datepicker__input',
            altField: '.js-availability-datepicker__alt-field'
        });

}



import * as rangeSlider from '@elements/range-slider';
rangeSlider.init();

import {createAjaxForm} from '@elements/ajax-form';


let ajaxFormSubmitOnChange = false;
if(matchMedia('(min-width: 768px)').matches) {
    ajaxFormSubmitOnChange = true;
}
if(find('.js-ajax-form-overlay')){
    createAjaxForm(find('.js-ajax-form-overlay'), {
        submitOnChange: ajaxFormSubmitOnChange,
        allowScriptTags:true,
        addUrlParams: true,
    }, {
        result: '.js-ajax-form-overlay__result',
        loading: '.js-ajax-form-overlay__loading',
        notifications: '.js-ajax-form-overlay__notifications',
        form: '.js-ajax-form-overlay__form',
        additionalForm: '.js-ajax-form-overlay__additional-form',
        errorArea: '.js-ajax-form-overlay__error-area',
        retry: '.js-ajax-form-overlay__retry',
        link: '.js-ajax-form-overlay__link',
        reset: '.js-ajax-form-overlay__reset',
    });
}

import * as overlayToggle from './scripts/overlay-toggle';
overlayToggle.init();

import * as account  from './scripts/account';
account.init();


import * as submitFormOnMobile from './scripts/tab-input';
submitFormOnMobile.init();