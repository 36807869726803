"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {hasClass, addClass, removeClass, closest, findIn, on, trigger} from '@elements/dom-utils';

export function init () {
    onFind('.js-expand-search', function (item) {
        on('click', function(){
            let expandContainer = findIn('.expand-container',closest('.extended-filter', item));
            if (!hasClass('is-expanded', expandContainer)){
                addClass('is-expanded', expandContainer);

                findIn('.expandable-search-field', expandContainer).focus();

                window.addEventListener("click", function _listener(evt) {
                    if (!closest('.extended-filter', item).contains(evt.target)) {
                        removeClass('is-expanded', expandContainer);

                        window.removeEventListener("click", _listener, true);
                    }
                }, true);

            }else {
                findIn('form', expandContainer).submit();
            }
        }, item);
    });

}